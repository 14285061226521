import { Style } from './Globals'
import AgbPdf from './agb_2025.pdf'
import { ReactComponent as PassportIcon } from './ui/icons/passport-svgrepo-com.svg'
import { ReactComponent as SignUpIcon } from './ui/icons/sign-up.svg'
import { ReactComponent as SeatIcon } from './ui/icons/seat-3.svg'
import { ReactComponent as PlanChangeIcon } from './ui/icons/route-path-way.svg'
import { ReactComponent as InsuranceIcon } from './ui/icons/insurance-27.svg'
import { ReactComponent as LuggageIcon } from './ui/icons/luggage-7.svg'
import { ReactComponent as ShipIcon } from './ui/icons/ship-24.svg'
import NavMagicContainer from './NavMagicContainer'
import { PropsWithChildren } from 'react'
import Button from './ui/buttons/Button'

const h2Class = `${Style.SubTitle} pb-2 font-gili pt-2 text-center`
const textBlockClass = `md:w-1/2 mx-auto mt-4 flex justify-center md:text-center`
const iconClass = `w-24 h-24 grow-0 shrink-0 mx-auto p-2`

type AgbElementProps = PropsWithChildren<{
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  customClass: string,
  text?: string,
  title: string,
}>

const AgbElement = ({ title, text, Icon, customClass, children }: AgbElementProps) => {
  return (
    <div className='my-12'>
      <Icon className={`${iconClass} ${customClass}`} />
      <h2 className={h2Class}>{title}</h2>
      <div className={`${textBlockClass}`}>
        {text || children}
      </div>
    </div>
  )
}

const Agb = () => {
  return (
    <div>
      <NavMagicContainer />

      <div className="p-4 container mx-auto">

        <h1 className={`pt-16 ${Style.Title} text-center`}>Allgemeine Geschäftsbedingungen</h1>

        <AgbElement
          title='ID / Pass'
          text="Sie brauchen eine gültige Identitätskarte oder einen Pass, um eine Reise antreten zu können."
          Icon={PassportIcon}
          customClass='fill-gray-200' />

        <AgbElement
          title='Anmeldung'
          text="Wir bitten um möglichst frühzeitige und schriftliche Anmeldung. Ihre Anmeldung wird von uns bestätigt. Mit der Anmeldung werden die Reisebedingungen anerkannt.        "
          Icon={SignUpIcon}
          customClass='fill-gray-200' />

        <AgbElement
          title='Sitzplätze'
          text="Die Plätze im Autocar werden in der Reihenfolge der Anmeldung vergeben."
          Icon={SeatIcon}
          customClass='fill-gray-200' />

        <AgbElement
          title='Programm-Änderungen'
          text="Wir behalten uns kleine Programm-Änderungen vor."
          Icon={PlanChangeIcon}
          customClass='fill-gray-200' />

        <AgbElement
          title='Haftung und Versicherung'
          Icon={InsuranceIcon}
          customClass='fill-gray-300'>
          <div>
            <div>
              Durch unsere Haftpflichtversicherung sind Sie während der Fahrt im Autocar, im Sinne der schweizerischen Gesetzgebung, vollumfänglich versichert. Ausserhalb des Autocars besteht unsererseits keine Haftung. Auf Wunsch besorgen wir für Sie eine Unfall- und Reisegepäckversicherung. Wir beraten Sie gerne.
            </div>

            <div className='mt-8'>
              Im Falle einer Annullation einer bereits bestätigten Buchung sind wir gezwungen, eine Bearbeitungsgebühr von Fr. 50.- zu verrechnen.
              <br />
              Ferner müssen wir bei einem Annullierungszeitpunkt von weniger als 31 Tagen vor der Abreise folgende Kosten in Rechnung stellen:


              <table className='table text-left mx-auto border-0 border-spacing-x-8 border-separate  my-8'>
                <thead>
                  <tr>
                    <th>Satz vom Pauschalpreis</th>
                    <th>Zeit zur Abreise</th>
                  </tr>
                </thead>

                <tbody>
                  <tr className='table-row'>
                    <td className='table-cell '>30%</td>
                    <td className='table-cell'>45 - 29 Tage</td>
                  </tr>

                  <tr className='table-row'>
                    <td className='table-cell'>50%</td>
                    <td className='table-cell'>28 - 15 Tage</td>
                  </tr>

                  <tr className='table-row'>
                    <td className='table-cell'>60%</td>
                    <td className='table-cell'>14 – 7 Tage</td>
                  </tr>

                  <tr className='table-row'>
                    <td className='table-cell'>70%</td>
                    <td className='table-cell'>6 - 3 Tage</td>
                  </tr>

                  <tr className='table-row'>
                    <td className='table-cell'>100%</td>
                    <td className='table-cell'>2 – 0 Tage</td>
                  </tr>
                </tbody>


              </table>



              Bei Buchungen mit Fähren werden die effektiven Stornierungskosten der Fähre weiterverrechnet. Auf Wunsch buchen wir Ihnen eine Annullationskosten-Versicherung. Wir beraten Sie gerne.
            </div>
          </div>


        </AgbElement>

        <AgbElement
          title='Fähre'
          text="Die Preise entsprechen dem Stand bei Angebotserstellung. Änderungen der
Treibstoffpreise, Steuern und Abgaben etc. sind nicht vor uns zu verantworten und
eine Preisangleichung bis zum Reisetermin ist vorbehalten."
          Icon={ShipIcon}
          customClass='fill-gray-300' />

        <AgbElement
          title='Reisegepäck'
          text="Die Gepäckstücke sind mit den Etiketten, welche Sie mit den Reiseunterlagen zugestellt erhalten, zu adressieren.
        Wir bitten Sie, das Verladen Ihres Gepäcks zu überwachen, da wir für eventuelle Beschädigungen und Verluste keine Haftung übernehmen können (Reisegepäck- Versicherung)."
          Icon={LuggageIcon}
          customClass='fill-gray-300' />

        <div className='flex justify-center mt-24'>
          <Button message='AGB als Pdf herunterladen' onClick={() => window.location.href = AgbPdf} />
        </div>

        <div className='h-24'></div>
      </div>
    </div>

  )
}

export default Agb