import ChrigiReicher from './team-photos/ChrigiReicher.png';
import DanielaHeckelmann from './team-photos/DanielaHeckelmann.png';
import HansjoergGysin from './team-photos/Hans-JoergGysin.png';
import HanspeterNiklaus from './team-photos/HanspeterNiklaus.png';
import IrisBuergin from './team-photos/IrisBuergin.png';
import KatjaWyss from './team-photos/KatjaWyss.png';
import MonikaGysin from './team-photos/MonikaGysin.png';
import ReginaHeinimann from './team-photos/ReginaHeinimann.png';
import UrsiSchaffner from './team-photos/UrsiSchaffner.png';
import { IMitarbeiter } from './About';

export const team: IMitarbeiter[] = [
  {
    firstName: "Hanspeter",
    lastName: "Niklaus",
    img: HanspeterNiklaus,
    role: "Geschäftsführer / Carchauffeur",
    sort: 50,
  },

  {
    firstName: "Hansjörg",
    lastName: "Gysin",
    img: HansjoergGysin,
    role: "Carchauffeur",
    sort: 35,
  },
  {
    firstName: "Iris",
    lastName: "Bürgin",
    img: IrisBuergin,
    role: "Reisebegleitung",
    sort: 30,
  },
  {
    firstName: "Regina",
    lastName: "Heinimann",
    img: ReginaHeinimann,
    role: "Reisebegleitung",
    sort: 20,
  },
  {
    firstName: "Monika",
    lastName: "Gysin",
    img: MonikaGysin,
    role: "Reisebegleitung",
    sort: 18,
  },
  {
    firstName: "Ursula",
    lastName: "Schaffner",
    img: UrsiSchaffner,
    role: "Reisebegleitung",
    sort: 16,
  },

  {
    firstName: "Christine",
    lastName: "Reicher",
    img: ChrigiReicher,
    role: "Postauto Chauffeuse",
    sort: 14,
  },

  {
    firstName: "Daniela",
    lastName: "Heckelmann",
    img: DanielaHeckelmann,
    role: "Büro / Reisebegleitung",
    sort: 12,
  },
  {
    firstName: "Katja",
    lastName: "Wyss",
    img: KatjaWyss,
    role: "Büro / Reisebegleitung",
    sort: 10,
  },
];
