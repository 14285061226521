/* Import Team pictures */
import {Style} from '../Globals'
import OldCar1 from '../cars/old-car-1.png';
import OldCar2 from '../cars/old-car-2.png';
import { team } from './team'
import NavMagicContainer from '../NavMagicContainer';

export interface IMitarbeiter {
  firstName: string,
  lastName: string, 
  role: string,
  img?: string,
  sort: number,
}

const About = () => {

  const bg = (mitarbeiter: IMitarbeiter) => {
    if(mitarbeiter.img){
      return { backgroundImage: `url(${mitarbeiter.img})` }
    }
    return { backgroundColor: '#cccccc' }
  }

  return (
    <div>
      <NavMagicContainer />

      <div className='container m-auto py-16'>
        <h1 className={`${Style.Title} text-center`}>Über A.N.K. - Tours</h1>

        <div className="mx-auto my-8 lg:w-1/2 flex flex-col space-y-4 text-center">
          <div>
        Im Jahr 2003 gründeten Franz Achermann, Hanspeter Niklaus und Beat Kaufmann die Firma A.N.K. Tours AG in Muttenz. 
          </div>
          <div>
        Unsere ersten beiden Fahrzeuge waren von der Marke Drögmöller. Drei Jahre später wurde der Fahrzeugpark mit einem MAN-Bus erweitert. 
        Die Drögmöller-Fahrzeuge wurden nach ein paar Jahren durch moderne Setra-Busse ersetzt. 
          </div>
          <div>
        2022 verlegten wir unseren Firmensitz nach Liestal an die Gerberstrasse, wo wir uns gut eingelebt haben.
          </div>
        </div>

      </div>

      <div className='flex flex-col md:flex-row flex-wrap justify-center mx-auto'>
        <div className='p-4'>
          <div className='h-[14rem] aspect-[4/3] bg-cover mx-auto' style={{ backgroundImage: `url(${OldCar1})` }}></div>
        </div>
        <div className='p-4'>
          <div className='h-[14rem] aspect-[4/3] bg-cover mx-auto' style={{ backgroundImage: `url(${OldCar2})` }}></div>
        </div>
      </div>


      <h2 className={`${Style.Title} pt-8 text-center mt-16`}>Unser zuverlässiges Team</h2>

      <div className={`flex flex-row flex-wrap px-4 mt-6 text-center`}>
      {
        team.sort((a,b) => b.sort - a.sort).map((mitarbeiter) => {
          return (
            <div className={`m-auto justify-center flex flex-col flex-wrap items-center max-w-1/4 p-8`}>
              <div className={`bg-cover rounded-full ${Style.Picture} w-56 h-56 `} style={bg(mitarbeiter)}></div>
              <div className='p-4 grow'>
                <div className={`text-3xl pt-2 ${Style.TextLight} tracking-wide`}>
                    {mitarbeiter.firstName} {mitarbeiter.lastName}
                </div>
                <div className={`text-lg mt-4 ${Style.TextLight}`}>
                  {mitarbeiter.role}
                </div>
              </div>
            </div>
          )
        })
      }
      </div>
    </div>
  )
}

export default About